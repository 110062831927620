<template>
  <div>
    <div class="breadcrumb">
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="content">
      <div class="bgFFF" style="padding: 30px 0 20px 0">
        <div class="statistics">
          <span>当前余额：{{ balanceDetail.balance || "0.00" }}元</span>
          <span>充值总额：{{ balanceDetail.totalRecharge || "0.00" }}元</span>
          <span>消费金额：{{ balanceDetail.totalConsum || "0.00" }}元</span>
          <span
            >消费折扣金额：{{ balanceDetail.totalDiscount || "0.00" }}元</span
          >
        </div>
      </div>
      <div class="searchWrapper bgFFF">
        <el-form :model="formInline" class="demo-form-inline" :inline="true">
          <el-form-item :label="$t('searchModule.Trading_Time')">
            <el-date-picker
              v-model="date"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Transaction_type')" style="padding-left: 10px">
            <el-select v-model="formInline.balanceBusiType">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收支类型" style="padding-left: 10px">
            <el-select v-model="formInline.incomeType">
              <el-option label="全部" value=""></el-option>
              <el-option label="收入" :value="1"></el-option>
              <el-option label="支出" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.plate_number')" style="padding-left: 10px">
            <el-autocomplete
              v-model="plateNumber"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入内容"
              @select="handleSelect"
            >
            </el-autocomplete>
          </el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searchData();
            "
            >{{ $t('button.search') }}</el-button
          >
          <el-button type="primary" icon="el-icon-delete" @click="reSetForm"
            >清空</el-button
          >
          <el-button type="primary" icon="el-icon-download" @click="exportFile"
            >{{ $t('button.export') }}</el-button
          >
        </el-form>
      </div>
      <div class="tableWrapper bgFFF paddingB10">
        <el-table :data="tableData">
          <el-table-column
            :label="$t('list.index')"
            type="index"
            align="center"
          ></el-table-column>
          <el-table-column
            v-for="item in tabCols"
            :label="item.label"
            :prop="item.prop"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { exportExcelNew, dateFormat } from "@/common/js/public.js";
export default {
  data() {
    return {
      balanceDetail: {},
      date: [],
      formInline: {
        balanceBusiType: "",
        incomeType: "",
        carId: "",
      },
      pageNum: 1,
      pageSize: 15,
      options: [
        {
          value: 1,
          label: "停车缴费",
        },
        {
          value: 2,
          label: "充值",
        },
        {
          value: 3,
          label: "停车退款",
        },
        {
          value: 4,
          label: "充值退款",
        },
      ],
      tableData: [],
      plateNumber: "",
      total: 0,
      tabCols: [
        {
          label: this.$t("list.order_ID"),
          prop: "orderId",
        },
        {
          label: "交易类型",
          prop: "transactionType",
          // formatter: (row) => {
          //   let desc = "";
          //   switch (row.transactionType) {
          //     case 1:
          //       desc = "停车退费";
          //       break;
          //     case 2:
          //       desc = "停车费代付";
          //       break;
          //     case 3:
          //       desc = "充值";
          //       break;
          //     case 4:
          //       desc = "退款";
          //       break;
          //     default:
          //       desc = "";
          //   }
          //   return desc;
          // },
        },
        {
          label: this.$t("list.plate_number"),
          prop: "plateNumber",
        },
        {
          label: "余额变化",
          prop: "money",
        },
        {
          label: "交易时间",
          prop: "transactionTime",
        },
        {
          label: "余额",
          prop: "balance",
        },
      ],
    };
  },
  methods: {
    handleSelect(item) {
      this.formInline.carId = item.carId;
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    searchData() {
      const opt = {
        url: "/acb/2.0/storeBalanceRecord/list",
        method: "get",
        data: {
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          storeId: this.$route.query.storeId,
          startTime: dateFormat(this.date[0], "yyyy-MM-dd HH:mm:ss"),
          endTime: dateFormat(this.date[1], "yyyy-MM-dd HH:mm:ss"),
          ...this.formInline,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = parseInt(res.value.total);
          }
        },
        fail: (err) => {},
      };
      this.$request(opt);
    },
    exportFile() {
      exportExcelNew("/acb/2.0/storeBalanceRecord/list/export", {
        storeId: this.$route.query.storeId,
        startTime: dateFormat(this.date[0], "yyyy-MM-dd HH:mm:ss"),
        endTime: dateFormat(this.date[1], "yyyy-MM-dd HH:mm:ss"),
        ...this.formInline,
      });
    },
    reSetForm() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    getBalance() {
      const opt = {
        url: `/acb/2.0/storeBalanceRecord/detail?storeId=${this.$route.query.storeId}`,
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.balanceDetail = res.value;
          }
        },
        fail: (err) => {},
      };
      this.$request(opt);
    },
  },
  mounted() {
    this.getBalance();
    this.searchData();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
  text-align: right;
  margin: 20px 0;
}

.content {
  overflow: hidden;
}

.searchWrapper {
  overflow: hidden;
  padding: 22px 22px 0;
  margin-bottom: 20px;
}

.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}

.statistics {
  margin-left: 22px;
  display: flex;
  gap: 200px;
}
</style>
